<template>
    <div class="row spacer-navigation spacer-navigation-overlap spacer-100-80">
        <div class="col-12 col-800-6">
            <div v-if="overview?.image" class="overview__image">
                <NuxtImg
                    sizes="xs:630 sm:767px md:1023px lg:1710px"
                    densities="1x 2x"
                    format="webp"
                    :src="overview?.image?.src"
                    :alt="overview?.image?.alt"
                    loading="lazy"
                />
            </div>
        </div>
        <div class="col-12 col-800-6 align-self-center">
            <h1
                class="scale-6 overview__title"
                :id="slugifyString(page?.title)"
            >
                {{ page.title }}
            </h1>
            <p class="scale-2 scale-2--lead">
                {{ overview.description }}
            </p>
        </div>
    </div>
    <div v-if="page?.body.length > 0" class="spacer-140-100">
        <CmsBody :page="page" />
    </div>
    <div class="row justify-content-center spacer-140-100">
        <div class="col-12 col-800-10 col-1200-8">
            <JobBenefits v-bind="benefits" />
        </div>
    </div>
    <div class="row justify-content-center spacer-50-40">
        <div class="col-12 col-800-10">
            <h2 class="scale-5" :id="slugifyString(t('job.overview.values'))">
                {{ t('job.overview.values') }}
            </h2>
        </div>
    </div>
    <CmsContentImageText02
        v-for="(value, index) in values"
        :key="value.id"
        v-bind="value"
        :flipped="index % 2 != 0"
        class="spacer-140-100"
    />
    <div class="row justify-content-center spacer-140-100">
        <div class="col-12 col-800-10 col-1200-8">
            <JobOpenPositions v-bind="openPositions" />
        </div>
    </div>
    <CmsContentAccordion v-bind="faq" class="spacer-140-100" />
    <CmsContentContactPerson v-bind="contactPerson" class="spacer-footer" />
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useHrefLang } from '~/composables/seo/hreflang';
import { slugifyString } from '~/utils/slugify';

const { t } = useI18n();

const overview = computed(() => page?.value?.data?.overview);
const benefits = computed(() => page?.value?.data?.benefits);
const values = computed(() => page?.value?.data?.values);
const openPositions = computed(() => page?.value?.job_postings);
const faq = computed(() => page?.value?.data?.faq);
const contactPerson = computed(() => page?.value?.data?.contact_person);

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

useHrefLang(page?.value?.meta?.hreflang);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.overview {
    &__image {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: clamp(400px, 30.56vw + 302.22px, 840px);

        img {
            position: relative;
            display: flex;
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
            transition: 0.5s ease;
        }

        @include media-breakpoint-down(800) {
            margin-bottom: 30px;
        }

        @include media-breakpoint-down(560) {
            margin: 0 $padding-container-negative 30px
                $padding-container-negative;
            width: auto;
        }
    }

    &__title {
        margin-bottom: 15px;
    }

    p {
        margin: 0;
    }
}
</style>
