<template>
    <div class="open-positions">
        <div class="spacer-50-40">
            <h2
                class="scale-5 scale-5--bold-uppercase"
                :id="anchorTitle"
                v-if="title"
            >
                {{ title }}
            </h2>
            <div v-if="info" class="equal-base open-positions__info">
                {{ info }}
            </div>
        </div>
        <div class="open-positions__grid">
            <div v-for="item in items" class="item">
                <JobTeaser :teaser="item" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAnchorTitle } from '~/composables/useAnchorTitle';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    info: {
        type: String,
    },
    items: {
        type: Array,
        default: () => [],
    },
});

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.open-positions {
    &__info {
        margin-top: 10px;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: clamp(50px, 0.69vw + 47.78px, 60px);
        row-gap: clamp(50px, 0.69vw + 47.78px, 60px);

        @include media-breakpoint-down(640) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}
</style>
