<template>
    <div class="benefits">
        <div class="spacer-50-40">
            <h2
                class="scale-5 scale-5--bold-uppercase"
                :id="anchorTitle"
                v-if="title"
            >
                {{ title }}
            </h2>
            <div class="equal-base benefits__info">{{ info }}</div>
        </div>
        <div class="benefits__grid">
            <div v-for="item in items" class="item">
                <h3 class="scale-2 scale-2--bold-uppercase item__heading">
                    {{ item.name }}
                </h3>
                <div class="equal-base">{{ item.description }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAnchorTitle } from '~/composables/useAnchorTitle';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    info: {
        type: String,
    },
    items: {
        type: Array,
        default: () => [],
    },
});

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.benefits {
    &__info {
        margin-top: 10px;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: clamp(40px, 0.69vw + 37.78px, 50px);
        row-gap: clamp(40px, 0.69vw + 37.78px, 50px);

        @include media-breakpoint-down(640) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}

.item {
    &__heading {
        margin-bottom: 10px;
    }
}
</style>
